@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Default styles for desktop and larger screens */
body {
  background-color: #1f1f1f;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  max-width: 150px; /* Adjust this value to your needs */
  width: 100%; /* This makes the image responsive */
  height: auto; /* This maintains the aspect ratio of the image */
}

.container {
  width: 80%;
  margin: 0 auto;
}

/* Styles for tablet screens */
@media screen and (max-width: 768px) {
  .container {
    width: 90%;
  }
}

/* Styles for mobile screens */
@media screen and (max-width: 480px) {
  body {
    font-size: 14px;
  }

  .container {
    width: 95%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input[type="text"] {
  background-color: #1f1f1f;
  color: white;
  outline: none;
  border: none;
  transition: all 0.3s ease-in-out;
}

input[type="text"]:focus {
  background-color: white;
  color: black;
  outline: none;
  border: none;
}

input[type="text"] + button {
  filter: invert(1); /* make the image white */
  transition: all 0.3s ease-in-out;
}

input[type="text"]:focus + button {
  filter: invert(0); /* make the image black */
  transition: all 0.3s ease-in-out;
}

.progress-bar {
  background-color: rgba(0, 0, 0, 0.2); /* white with 50% opacity */
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}